import React, { ReactNode, useContext, useMemo, useState } from "react";

type ContextValue = {
  currentGeohash: string;
  setCurrentGeohash: (value: string) => void;
};

const AlchemaContext = React.createContext<ContextValue | undefined>(undefined);

interface Props {
  children?: ReactNode;
}

export const AlchemaProvider: React.FC<Props> = (props) => {
  const [currentGeohash, setCurrentGeohash] = useState<string>(initialGeohash);

  const value = useMemo(
    () => ({
      currentGeohash,
      setCurrentGeohash,
    }),
    [currentGeohash, setCurrentGeohash]
  );

  return <AlchemaContext.Provider value={value} {...props} />;
};

export const useAlchema = (): ContextValue => {
  const context = useContext(AlchemaContext);
  if (context === undefined) {
    throw new Error("Alchema must be used within an AlchemaProvider");
  }
  return context;
};

const initialGeohash = (window as any)?.location.hash.slice(1) || "dhxnf4vmx";
