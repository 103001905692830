import { geohashToArea, getCenterPoint } from ".";
import { LatLngArea, LatLngPoint } from "../types";

interface GeoHashInterface {
  base32EvenMap: { [key: string]: number };
  base32OddMap: { [key: string]: number };
  encode: (lat: number, lng: number, precision?: number) => string;
  decode: (hashcode: string, delta?: boolean) => number[] | object;
}

export const GEOHASH_EVEN_DICT: string = "bcfguvyz89destwx2367kmqr0145hjnp";
export const GEOHASH_ODD_DICT: string = "prxznqwyjmtvhksu57eg46df139c028b";

class GeoHash implements GeoHashInterface {
  base32EvenMap: { [key: string]: number };
  base32OddMap: { [key: string]: number };

  constructor() {
    this.base32EvenMap = this.createBase32Map(GEOHASH_EVEN_DICT);
    this.base32OddMap = this.createBase32Map(GEOHASH_ODD_DICT);
  }

  createBase32Map(base32: string): { [key: string]: number } {
    return base32.split("").reduce((prev, curr, idx) => {
      prev[curr] = idx;
      return prev;
    }, {} as { [key: string]: number });
  }
  private encodeInternal(lat: number, lon: number, precision: number): string {
    let latOffset = 90,
      lngOffset = -180,
      latSectionDeg = 180,
      lngSectionDeg = 360;

    let geohash = "";

    for (let i = 0; i < precision; i++) {
      const odd = i % 2;
      const geohashDict = odd ? GEOHASH_ODD_DICT : GEOHASH_EVEN_DICT;

      const rowsNumber = odd ? 8 : 4;
      const columnsNumber = odd ? 4 : 8;

      latSectionDeg = latSectionDeg / rowsNumber;
      lngSectionDeg = lngSectionDeg / columnsNumber;

      const rowIndex = Math.floor((latOffset - lat) / latSectionDeg);
      const colIndex = Math.floor((lon - lngOffset) / lngSectionDeg);

      geohash += geohashDict[rowIndex * columnsNumber + colIndex];

      latOffset -= rowIndex * latSectionDeg;
      lngOffset += colIndex * lngSectionDeg;
    }

    return geohash;
  }

  encode(lat: number, lon: number, precision: number = 12): string {
    return this.encodeInternal(lat, lon, precision);
  }
  private decodeInternal(hashcode: string): LatLngArea {
    const area = geohashToArea(hashcode);
    if (!area) throw new Error(`Cannot decode geohash: ${hashcode}`);
    return area;
  }

  decode(hashcode: string, delta: boolean = false): LatLngPoint | object {
    const area = this.decodeInternal(hashcode);
    const center = getCenterPoint(area);

    if (!delta) return center;

    return {
      lat: center.lat,
      lon: center.lng,
      latErr: (area.end.lat - area.start.lat) / 2,
      lonErr: (area.end.lng - area.start.lng) / 2,
    };
  }

  bbox(hashcode: string): LatLngArea {
    return this.decodeInternal(hashcode);
  }

  neighbors(hashcode: string): string[] {
    const area = this.decodeInternal(hashcode);
    const center = getCenterPoint(area);
    const latSectionDeg = (area.end.lat - area.start.lat) / 2;
    const lngSectionDeg = (area.end.lng - area.start.lng) / 2;

    const precision = hashcode.length;

    return [
      this.encodeInternal(center.lat + latSectionDeg, center.lng, precision), // North
      this.encodeInternal(center.lat - latSectionDeg, center.lng, precision), // South
      this.encodeInternal(center.lat, center.lng - lngSectionDeg, precision), // West
      this.encodeInternal(center.lat, center.lng + lngSectionDeg, precision), // East
      this.encodeInternal(
        center.lat + latSectionDeg,
        center.lng - lngSectionDeg,
        precision
      ), // Northwest
      this.encodeInternal(
        center.lat - latSectionDeg,
        center.lng - lngSectionDeg,
        precision
      ), // Southwest
      this.encodeInternal(
        center.lat + latSectionDeg,
        center.lng + lngSectionDeg,
        precision
      ), // Northeast
      this.encodeInternal(
        center.lat - latSectionDeg,
        center.lng + lngSectionDeg,
        precision
      ), // Southeast
    ];
  }
}

export default GeoHash;
