import "leaflet/dist/leaflet.css";
import React from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import MapComponent from "./components/Map";

export enum FetchStatus {
  Idle,
  Loading,
  Success,
  Error,
}

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="flex flex-col h-full">
        <div className="w-full fixed z-20 left-0 top-0">
          <div className="flex items-center justify-between p-2 bg-none">
            <div className="text-4xl text-[#555] geohash-cell-label -mb-2">
              World of Alchema
            </div>
          </div>
        </div>
        <div className="w-full h-full z-20 pointer-events-none overflow-hidden fixed">
          <div className="">
            <div id="foglayer_01" className="fog">
              <div className="image01"></div>
              <div className="image02"></div>
            </div>
            <div id="foglayer_02" className="fog">
              <div className="image01"></div>
              <div className="image02"></div>
            </div>
            <div id="foglayer_03" className="fog">
              <div className="image01"></div>
              <div className="image02"></div>
            </div>
          </div>
        </div>

        <div className="z-10 filter contrast-75 saturate-50">
          <MapComponent />
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default App;
