import React, { ReactNode } from "react";
import { AlchemaProvider } from "./alchema";

interface Props {
  children: ReactNode;
}

const AppContext: React.FC<Props> = ({ children }) => (
  <AlchemaProvider>{children}</AlchemaProvider>
);

export default AppContext;
